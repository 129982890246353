exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-clienti-tsx": () => import("./../../../src/pages/clienti.tsx" /* webpackChunkName: "component---src-pages-clienti-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-politica-de-confidentialitate-tsx": () => import("./../../../src/pages/politica-de-confidentialitate.tsx" /* webpackChunkName: "component---src-pages-politica-de-confidentialitate-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-termeni-si-conditii-tsx": () => import("./../../../src/pages/termeni-si-conditii.tsx" /* webpackChunkName: "component---src-pages-termeni-si-conditii-tsx" */),
  "component---src-pages-transport-tsx": () => import("./../../../src/pages/transport.tsx" /* webpackChunkName: "component---src-pages-transport-tsx" */),
  "component---src-templates-bags-tsx": () => import("./../../../src/templates/Bags.tsx" /* webpackChunkName: "component---src-templates-bags-tsx" */),
  "component---src-templates-boards-serving-tsx": () => import("./../../../src/templates/BoardsServing.tsx" /* webpackChunkName: "component---src-templates-boards-serving-tsx" */),
  "component---src-templates-bookmarks-tsx": () => import("./../../../src/templates/Bookmarks.tsx" /* webpackChunkName: "component---src-templates-bookmarks-tsx" */),
  "component---src-templates-cards-tsx": () => import("./../../../src/templates/Cards.tsx" /* webpackChunkName: "component---src-templates-cards-tsx" */),
  "component---src-templates-mugs-tsx": () => import("./../../../src/templates/Mugs.tsx" /* webpackChunkName: "component---src-templates-mugs-tsx" */),
  "component---src-templates-prints-tsx": () => import("./../../../src/templates/Prints.tsx" /* webpackChunkName: "component---src-templates-prints-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-product-unique-tsx": () => import("./../../../src/templates/ProductUnique.tsx" /* webpackChunkName: "component---src-templates-product-unique-tsx" */),
  "component---src-templates-stickers-tsx": () => import("./../../../src/templates/Stickers.tsx" /* webpackChunkName: "component---src-templates-stickers-tsx" */)
}

